import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Image from "../../../components/image"

import styles from "./wayfinding.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  const coverImages = article.coverImages ? article.coverImages[0] : null

  // Assets
  const parallax1 = data.craft.parallax1[0]
  const parallax2 = data.craft.parallax2[0]
  const parallax3 = data.craft.parallax3[0]
  const parallax4 = data.craft.parallax4[0]

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <aside className={`${styles.pullquote} ${styles.bgOrange}`}>
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-10-tablet is-8-desktop">
                    <p className={`mb-4 ${styles.pullquoteText}`}>
                      <span className="italic">Barn's burnt down</span> – now I
                      can see the moon.
                    </p>
                    <cite className="subhead subhead--responsive fw-bold">
                      - Mizuta Masahide, a 17th century Japanese poet and
                      samurai.
                    </cite>
                  </div>
                </div>
              </div>
            </aside>
            <div className={`${styles.text} ${styles.bgMint}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        The coronavirus has brought us many things, but what
                        I’ve been most overwhelmed by is its all-consuming
                        narrative. It’s hard to tell a story now that isn’t
                        framed by the virus and its aftershocks. And that
                        includes this one.
                      </p>
                      <p>
                        The stories we hear today overwhelmingly focus on an
                        avalanche of insurmountable issues. They confront us
                        with a sense of helplessness and passivity. These
                        stories are then dissected, revised, iterated and
                        repeated upon ad infinitum. We are subjected to many hot
                        takes, to various half-hearted assertions, to numerous
                        new angles and points-of-view. This is the state of our
                        collective narrative today: We are encased in a
                        reactionary mode, where commentary is confused with
                        creation, where the narrative agenda is set by a few
                        people, and the rest of us just comment upon it. The
                        result is an echo chamber of malaise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.pullquote} ${styles.noQoute} ${styles.pullquoteTopLeft} ${styles.bgGreen}`}
            >
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-offset-1-mobile is-offset-0-tablet is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      But the reality is that there are a lot of stories that
                      aren’t being told right now. We’re missing a crucial part
                      of what could help us out of this: stories that help us
                      see <span className="italic">what we can be</span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.text} ${styles.bgPurpleLight}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        In addition to being a health crisis, an economic
                        decimator, and part of the impetus for massive social
                        fracturing, the pandemic is now a collective narrative
                        we must overcome. The stories we’re surrounded by not
                        only frame our present, but our future.
                      </p>
                      <p>
                        Right now, there’s a lack of stories that act as
                        wayfinding—visions of what we should become.
                      </p>
                      <p>
                        Now is the time for artists and creators who are
                        self-determined, visionary, and driven by something
                        bigger than logical next steps. This is a moment for
                        people who can envision a different world and help
                        transport us there.
                      </p>
                      <p>
                        The plight of the pandemic is far from over. Many people
                        look forward to a new or next normal. Yet what has
                        become apparent to me is what a lost opportunity
                        normalcy is. We need many possibilities that evolve as
                        rapidly as they are formed. We need to feel overwhelmed
                        by choice—by an expansive view of what’s possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              data-scroll=""
              className={`${styles.parallax} ${styles.bgPurpleLight}`}
            >
              <div className="wrapper">
                <div className="columns is-mobile is-multiline no-vertical-gap">
                  <div className="column is-9 is-offset-3 is-7-tablet is-offset-4-tablet">
                    <Image
                      className={styles.parallaxBack}
                      width={parallax1.width}
                      height={parallax1.height}
                      sizes="(min-width: 1424px) 998px, (min-width 768px) calc(calc(100vw - 5rem) * .75), calc(calc(100vw - 2.5rem) * .75)"
                      url={parallax1.url}
                    />
                  </div>
                  <div className="column is-7 is-6-tablet is-offset-1-tablet">
                    <Image
                      className={styles.parallaxFront}
                      width={parallax2.width}
                      height={parallax2.height}
                      sizes="(min-width: 1424px) 767px, (min-width 768px) calc(calc(100vw - 5rem) * .5), calc(calc(100vw - 2.5rem) * .5)"
                      url={parallax2.url}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.text} ${styles.noBorder} ${styles.bgPurpleLight}`}
            >
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <h2 className={styles.titleText}>
                        Creativity helps us see
                      </h2>
                      <p>
                        Everything begins with feeling. It’s from that point
                        that we act or we don’t. The pandemic has brought on a
                        cultural hesitancy. Our gears are stuck in neutral. This
                        is what being panic-stricken looks like.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.pullquote} ${styles.noQoute} ${styles.pullquoteLeft} ${styles.bgPurple}`}
            >
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-offset-1-mobile is-offset-0-tablet is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      How has anyone ever moved radically forward? Not with
                      practicality or measured playbooks. But with audacity and
                      desire: What do we want to be?{" "}
                      <span className="italic">
                        What does it look and smell and feel like?
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.text} ${styles.bgMint}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        Today, setting aside bad news can be a radical act. The
                        present moment is tragic and crippling—but life has
                        always been that way. There have always been harrowing
                        struggles, mountains that have felt insurmountable. And
                        just as there has always been sorrow and hardship, there
                        has also always been beauty, strangeness, and laughter
                        occurring in unison. We are not prisoners to a single
                        emotional state.
                      </p>
                      <p>
                        The most radical thing we can do today is create. To
                        shape our own uniquely viable path forward, and to be
                        inspired by others who are doing the same. The
                        alternative is stasis.
                      </p>
                      <p>
                        We say we value originality as a culture. And yet we are
                        currently being defined by the redundant and inward
                        conversations that are repeated around us. The narrative
                        of today is not wrong, it just isn’t the full picture.
                        There is nothing challenging us to divine a better
                        future. There is simply a retreat.
                      </p>
                      <p>
                        The killing of George Floyd rightly spurred a revolt
                        against centuries of systemic racism. This is
                        incredible: many people now see a part of the picture
                        they were apparently blind to before. But to stop at
                        simply seeing is a misstep: the next act must be a
                        creative one. The most interesting signs of momentum in
                        this equity movement are all creative acts—novel
                        solutions that show us different ways of living.
                        Abolishing the police no longer seems outlandish to
                        many. My own neighborhood formed a hyper-vigilant
                        watch-group to replace the police, but the conversation
                        has been less about policing and more about
                        understanding. No system is considered sacred. Instead
                        there is a sense of urgency in moving forward, with
                        equity center-stage.
                      </p>
                      <p>
                        I've fallen for only a few pieces of media that have
                        emerged during the pandemic. One of them is the New York
                        Times podcast{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.nytimes.com/column/sugar-calling"
                        >
                          Sugar Calling
                        </a>
                        , which has author Cheryl Strayed calling her favorite
                        legendary writers over the age of 60, asking them for
                        advice.
                      </p>
                      <p>
                        One thing has struck me about these interviews is that
                        none of these uber-famous writers dwell on the present.
                        In fact, all of them have purposefully created some
                        distance from it. Alice Walker points out that she’s at
                        a stage in her life where she was already prepared for
                        the unknown, so this hasn’t really shaken her all that
                        much. Margaret Atwood talks about getting through
                        anything and everything with her work ethic. George
                        Saunders manages to see his shelter-in-place life as
                        something that's really not all that different from his
                        life before. All of them have some distance from the
                        day-to-day malaise—they separate themselves from the
                        shared narrative so they can write their own story.
                        They've found control through creation. They’re carried
                        by their own view of the big picture, and they chase a
                        clear image of a world and a life beyond.
                      </p>
                      <h2 className={styles.titleText}>
                        Values are a framework for exploring the unknown
                      </h2>
                      <p>
                        We have always lived with the unknown, but rarely has it
                        felt so present and threatening. We often advise clients
                        that you should use your values as a framework for
                        exploring uncertainty. We’ve actually taken this quite
                        literally at our own company, even asking “what would
                        our values say?” when we’re stuck or swirling on
                        internal decisions.
                      </p>
                      <p>
                        It would be impossible to create our way through this
                        without values. They are the tenets that have cemented
                        us to one another, yet they are also malleable enough to
                        help us meet new demands.
                      </p>
                      <p>
                        I've had what I can only describe as a reawakening with
                        my values. I’ve remembered what I care about—that I
                        value my curiosity and creativity above all else. And
                        I’ve had the time to frequently ask myself my favorite
                        question, “What makes this interesting?”
                      </p>
                      <p>
                        There’s been nothing to do but go inward, and I’ve
                        remembered why I became a writer in the first place: to
                        spend time with myself and other writers, in the pursuit
                        of something beyond. I’ve always had a good time living
                        in my head, and with the pandemic I’ve rediscovered the
                        many internal worlds at play.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              data-scroll=""
              className={`${styles.parallax} ${styles.bgMint}`}
            >
              <div className="wrapper">
                <div className="columns is-mobile is-multiline no-vertical-gap">
                  <div className="column is-9 is-7-tablet is-offset-1-tablet">
                    <Image
                      className={styles.parallaxBack}
                      width={parallax3.width}
                      height={parallax3.height}
                      sizes="(min-width: 1424px) 998px, (min-width 768px) calc(calc(100vw - 5rem) * .75), calc(calc(100vw - 2.5rem) * .75)"
                      url={parallax3.url}
                    />
                  </div>
                  <div className="column is-7 is-offset-5 is-6-tablet is-offset-5-tablet">
                    <Image
                      className={styles.parallaxFront}
                      width={parallax4.width}
                      height={parallax4.height}
                      sizes="(min-width: 1424px) 767px, (min-width 768px) calc(calc(100vw - 5rem) * .5), calc(calc(100vw - 2.5rem) * .5)"
                      url={parallax4.url}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.text} ${styles.bgMint} ${styles.noBorder}`}
            >
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        It’s in experiencing this stretch of solitude out of its
                        supposed order (I thought that maybe this wouldn't
                        happen until retirement, optimistically 40 or so years
                        away) that I’ve been able to see where to go next. I’ll
                        be the first to admit that over the past couple years
                        I’ve put my personal writing aside in favor of "work,"
                        but suddenly I find myself writing to please myself,
                        with no plans to involve anyone else. It's once again
                        become a kind of meditation.
                      </p>

                      <p>
                        I have remembered that I value the act of turning off
                        one narrative to shape my own. I’m finding myself
                        writing and reading more because there’s really nothing
                        else that feels as urgent or as satisfying. It’s likely
                        that nothing will materialize from what I create during
                        this time. And that’s fine. It’s allowed me to embody a
                        mindset of my choosing, to chase thoughts that reflect
                        my ideals.
                      </p>

                      <h2 className={styles.titleText}>
                        If we emerge unchanged, we have failed
                      </h2>

                      <p>
                        This issue of Athena is about creating more human
                        futures. What is a human future? We began thinking this
                        work would be largely about the interplay of humans and
                        machines; but instead it’s become more about how to
                        live, the values we should uphold, and about the guiding
                        forces that dictate our everyday attention and demand
                        our most precious commodity: time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.pullquote} ${styles.noQoute} ${styles.pullquoteNoVertical} ${styles.bgBlue}`}
            >
              <div className="wrapper">
                <div className="columns is-mobile is-centered">
                  <div className="column is-11-mobile is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      <span className="italic">
                        A human future is one that’s driven by our boundless
                        imagination.
                      </span>{" "}
                      And that world only opens up if we begin to tell ourselves
                      different stories.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.text}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        Are we going to let other people decide the future we’re
                        going to live in? Or will we take control and establish
                        the narrative for ourselves? The stories we create now
                        are essential. If we emerge from this unchanged, we've
                        failed. It's time to imagine the stories that we want to
                        make real.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      parallax1: assets(id: 13231) {
        url
        width
        height
      }
      parallax2: assets(id: 13232) {
        url
        width
        height
      }
      parallax3: assets(id: 13233) {
        url
        width
        height
      }
      parallax4: assets(id: 13234) {
        url
        width
        height
      }
    }
  }
`
